var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { class: _vm.$style.block }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-xl-12" }, [
          _c("div", { class: _vm.$style.inner }, [
            _c(
              "div",
              { class: _vm.$style.form },
              [
                _vm._m(0),
                _c(
                  "a-form",
                  { staticClass: "login-form" },
                  [
                    _c(
                      "a-form-model-item",
                      {
                        ref: "email",
                        attrs: { label: "メールアドレス", prop: "email" }
                      },
                      [
                        _c(
                          "a-input",
                          {
                            attrs: { placeholder: "Email" },
                            on: {
                              blur: function() {
                                _vm.$refs.email.onFieldBlur()
                              }
                            },
                            model: {
                              value: _vm.form.email,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "email", $$v)
                              },
                              expression: "form.email"
                            }
                          },
                          [
                            _c("a-icon", {
                              staticStyle: { color: "rgba(0,0,0,.25)" },
                              attrs: { slot: "prefix", type: "user" },
                              slot: "prefix"
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "text-primary utils__link--blue utils__link--underlined",
                            attrs: { to: "/user/login" }
                          },
                          [_vm._v("ログイン画面に戻る")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "form-actions" },
                      [
                        _c(
                          "a-button",
                          {
                            staticClass: "login-form-button",
                            attrs: { type: "primary", loading: _vm.loading },
                            on: { click: _vm.onSubmit }
                          },
                          [
                            _vm._v(
                              "\n                  パスワード再設定のリンクを送る\n                "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", { staticClass: "text-uppercase" }, [
      _c("strong", [_vm._v("パスワード再設定")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }