<template>
  <div>
    <div :class="$style.block">
      <div class="row">
        <div class="col-xl-12">
          <div :class="$style.inner">
            <div :class="$style.form">
              <h4 class="text-uppercase">
                <strong>パスワード再設定</strong>
              </h4>
              <a-form class="login-form">
                <a-form-model-item ref="email" label="メールアドレス" prop="email">
                  <a-input
                    placeholder="Email"
                    v-model="form.email"
                    @blur="
                      () => {
                        $refs.email.onFieldBlur()
                      }
                    "
                  >
                    <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25);"/>
                  </a-input>
                </a-form-model-item>
                <div>
                  <router-link
                    class="text-primary utils__link--blue utils__link--underlined"
                    to="/user/login"
                  >ログイン画面に戻る</router-link>
                </div>
                <div class="form-actions">
                  <a-button type="primary" @click="onSubmit" class="login-form-button" :loading="loading">
                    パスワード再設定のリンクを送る
                  </a-button>
                </div>
              </a-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  data: function () {
    return {
      loading: false,
      form: {
        email: '',
      },
    }
  },
  methods: {
    onSubmit() {
      this.loading = true
      const _this = this
      const result = Vue.prototype.$api.send('post', 'auth/password_reset_by_email', this.form)
      result.then(response => {
        this.$notification['success']({
          message: 'パスワード再設定のリンクを送信しました。',
        })
        this.loading = false
        _this.$router.push('/user/login')
      })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': パスワードの変更に失敗しました。',
          })
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
